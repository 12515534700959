<template>
  <div class='wrap relative' :style="'width: ' + map_width + '; height:' + map_height">
    <div id="mapImageContainer" :style="'width: ' + map_width + '; height:' + map_height" v-if='map_image_data'>
      <img :src='map_image_data' alt=''>
    </div>

    <div v-else>
      <div id="mapContainer" ref="mapContainer" :style="'width: ' + map_width + '; height:' + map_height"></div>
      <div class='absolute zoom-wrap'>
        <ZoomMap
            @scale-map="scaleMap"
        />
      </div>
    </div>
  </div>
</template>

<script>
import H from '@here/maps-api-for-javascript';
import ZoomMap from './ZoomMap.vue';
import structuredClone from "@ungap/structured-clone";

export default {
  name: "HereMap",
  components: {
    ZoomMap
  },
  props: {
    routeData: {
      type: Object,
      required: false,
      default: () => ({})
    },
    is_edit: {
      type: Boolean,
      default: false
    },
    reset_all: {
      type: Boolean,
      default: false
    },
    multi_points_center: {
      type: Boolean,
      default: false
    },
    selected_points: {
      type: Array,
      required: false
    },
    is_plan: {
      type: Boolean,
      default: true
    },
    map_width: {
      type: String,
      default: '100%'
    },
    map_height: {
      type: String,
      default: '100vh'
    },
    zoom_default: {
      type: Number,
      default: 6.2
    },
    start_printing: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      map: null,
      map_image_data: '',
      behavior: null,
      generated_route: false,
      stop_dragging: false,
      cloned_points: null
    }
  },
  methods: {
    scaleMap(zoomIn) {

      const currentZoom = this.map.getZoom();
      const newZoom = zoomIn ? currentZoom + 0.25 : currentZoom - 0.25;
      this.map.setZoom(newZoom);
    },
    async displayRoute() {

      if (!this.routeData.routes) {
        return;
      }
      if(this.multi_points_center) {
        return await this.renderClonedMarkers();
      }

      const colors = ['rgba(0, 0, 255, 0.5)', 'rgba(0, 128, 0, 0.5)', 'rgba(255, 165, 0, 0.5)', 'rgba(128, 0, 128, 0.5)', 'rgba(9, 4, 70, 0.5)', 'rgba(28, 93, 153, 0.5)', 'rgba(0, 183, 145, 0.5)', 'rgba(127, 184, 0, 0.5)', 'rgba(0, 166, 237, 0.5)'];

      this.generated_route = true;
      const route = this.routeData.routes[0];

      await this.map.removeObjects(this.map.getObjects());
      let latitudes = [];
      let longitudes = [];

      route.sections.forEach((section, index) => {
          const linestring = H.geo.LineString.fromFlexiblePolyline(section.polyline);
          const routeLine = new H.map.Polyline(linestring, {
            style: {strokeColor: colors[index % colors.length], lineWidth: 10}
          });
          this.map.addObject(routeLine);
          linestring.getLatLngAltArray().forEach((value, index) => {
            if (index % 3 === 0) latitudes.push(value);
            if (index % 3 === 1) longitudes.push(value);
          });
      });
      await this.renderMarkers();
      this.map.getObjects().forEach(obj => {
        if (obj instanceof H.map.Marker) {
          obj.draggable = false;
          const point = obj.getGeometry();
          latitudes.push(point.lat);
          longitudes.push(point.lng);
        }
      });
      if (latitudes.length > 0 && longitudes.length > 0) {
        const minLat = Math.min(...latitudes);
        const maxLat = Math.max(...latitudes);
        const minLng = Math.min(...longitudes);
        const maxLng = Math.max(...longitudes);
        const latDelta = (maxLat - minLat) * 0.1;
        const lngDelta = (maxLng - minLng) * 0.58;
        const paddedMinLat = minLat - latDelta;
        const paddedMaxLat = maxLat + latDelta;
        const paddedMinLng = minLng - lngDelta;
        const paddedMaxLng = maxLng + lngDelta;
        const paddedBoundingRect = new H.geo.Rect(paddedMinLat, paddedMinLng, paddedMaxLat, paddedMaxLng);
        this.map.getViewModel().setLookAtData({bounds: paddedBoundingRect}, true);

      }

      this.map.removeEventListener('dragstart', () => {});
      this.map.removeEventListener('drag', () => {});
      this.map.removeEventListener('dragend', () => {});

    },

    async renderClonedMarkers() {
      await this.map.removeObjects(this.map.getObjects());

        let points = this.selected_points;
          points = this.routeData.routes[0].sections.map(section => {
            return {
              lat: section.departure.place.originalLocation.lat,
              lng: section.departure.place.originalLocation.lng
            };
          })
          points.push({
            lat: this.routeData.routes[0].sections[this.routeData.routes[0].sections.length - 1].arrival.place.originalLocation.lat,
            lng: this.routeData.routes[0].sections[this.routeData.routes[0].sections.length - 1].arrival.place.originalLocation.lng
          });
        points.forEach((point, index) => {

          const marker = new H.map.Marker({
            lat: point.lat,
            lng: point.lng
          }, {
            icon: new H.map.Icon(this.generateMarkerSVG(this.alphabet[index % this.alphabet.length]))
          });
          marker.draggable = true;
          this.map.addObject(marker);
        });

      await this.setupDragging();
      let latitudes = [];
      let longitudes = [];
      this.map.getObjects().forEach(obj => {
        if (obj instanceof H.map.Marker) {
          const point = obj.getGeometry();
          latitudes.push(point.lat);
          longitudes.push(point.lng);
        }
      });
      if (latitudes.length > 0 && longitudes.length > 0) {
        const minLat = Math.min(...latitudes);
        const maxLat = Math.max(...latitudes);
        const minLng = Math.min(...longitudes);
        const maxLng = Math.max(...longitudes);
        const latDelta = (maxLat - minLat) * 0.1;
        const lngDelta = (maxLng - minLng) * 0.58;
        const paddedMinLat = minLat - latDelta;
        const paddedMaxLat = maxLat + latDelta;
        const paddedMinLng = minLng - lngDelta;
        const paddedMaxLng = maxLng + lngDelta;
        const paddedBoundingRect = new H.geo.Rect(paddedMinLat, paddedMinLng, paddedMaxLat, paddedMaxLng);
        this.map.getViewModel().setLookAtData({bounds: paddedBoundingRect}, true);
      }
    },

    async renderMarkers() {

      let points = this.selected_points;
      if(!this.is_plan) {
        points = this.routeData.routes[0].sections.map(section => {
          return {
            lat: section.departure.place.originalLocation.lat,
            lng: section.departure.place.originalLocation.lng
          };
        })
        points.push({
          lat: this.routeData.routes[0].sections[this.routeData.routes[0].sections.length - 1].arrival.place.originalLocation.lat,
          lng: this.routeData.routes[0].sections[this.routeData.routes[0].sections.length - 1].arrival.place.originalLocation.lng
        });
      }
      points.forEach((point, index) => {
        const marker = new H.map.Marker({
          lat: point.lat,
          lng: point.lng
        }, {
          icon: new H.map.Icon(this.generateMarkerSVG(this.alphabet[index % this.alphabet.length]))
        });
        marker.draggable = false;
        this.map.addObject(marker);
      });
    },

    async initMap() {

      const platform = new H.service.Platform({
        apikey: process.env.VUE_APP_HERE_API_KEY
      });
      const defaultLayers = platform.createDefaultLayers();
      const map = new H.Map(
          this.$refs.mapContainer,
          defaultLayers.vector.normal.truck,
          {
            zoom: this.zoom_default,
            center: {  lat: 54.195949, lng: -5.131529}
          }
      );
      this.map = map;
      const mapEvents = new H.mapevents.MapEvents(this.map);
      this.behavior = await new H.mapevents.Behavior(mapEvents);

      await H.ui.UI.createDefault(map, defaultLayers);

      if(!this.is_plan) {
        this.setupStoredRoute();
      }
    },
    async setupStoredRoute() {

      if (!this.routeData) {
        return;
      }
      await this.displayRoute();
    },
    async renderAddedPoints(points, changed_point_index) {
      if (this.generated_route) {
        return;
      }
      await this.map.removeObjects(this.map.getObjects());
      points.forEach((point, index) => {
        let icon = new H.map.Icon(this.generateMarkerSVG(this.alphabet[index]));
        let marker = new H.map.Marker({ lat: point.lat, lng: point.lng }, { icon: icon });

        marker.draggable = true;
        this.map.addObject(marker);
      });

      await this.setupDragging();
      if (this.$route.name !== 'HereRePlan') {
        await this.zoomPoints(changed_point_index);
      }
    },

    async zoomPoints(changed_point_index = 0 ) {
      let latitudes = [];
      let longitudes = [];
      this.map.getObjects().forEach(obj => {
        if (obj instanceof H.map.Marker) {
          const point = obj.getGeometry();
          latitudes.push(point.lat);
          longitudes.push(point.lng);
        }
      });
        const paddedBoundingRect = new H.geo.Point(latitudes[changed_point_index], longitudes[changed_point_index]);
        await this.map.getViewModel().setLookAtData({bounds: paddedBoundingRect, zoom: 14}, true);
    },

    setupDragging() {

      if (!this.map || !this.behavior) {
        return;
      }


      this.map.addEventListener('dragstart', (ev) => {
        var target = ev.target;
        if (target instanceof H.map.Marker) {
          var pointer = ev.currentPointer;
          var targetPosition = this.map.geoToScreen(target.getGeometry());
          target['offset'] = new H.math.Point(pointer.viewportX - targetPosition.x, pointer.viewportY - targetPosition.y);
          this.behavior.disable(H.mapevents.Behavior.Feature.PANNING);
        }
      }, false);

      this.map.addEventListener('drag', (ev) => {
        var target = ev.target,
            pointer = ev.currentPointer;
        if (target instanceof H.map.Marker) {
          target.setGeometry(this.map.screenToGeo(pointer.viewportX - target['offset'].x, pointer.viewportY - target['offset'].y));
        }
      }, false);

      this.map.addEventListener('dragend', (ev) => {
        const target = ev.target;
        if (target instanceof H.map.Marker) {
          this.behavior.enable(H.mapevents.Behavior.Feature.PANNING);
          const markers = this.map.getObjects().filter(obj => obj instanceof H.map.Marker);
          const updatedPoints = markers.map(marker => {
            const position = marker.getGeometry();
            return {
              lat: position.lat,
              lng: position.lng
            };
          });
          this.$emit('update_points', updatedPoints);
        }
      }, false);
    },
    generateMarkerSVG(letter) {
      return `<svg width="30" height="36" viewBox="0 0 30 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M25.253 25.253C23.1329 27.3732 18.5489 32.3666 16.1592 34.9844C15.537 35.6659 14.463 35.6659 13.8408 34.9844C11.4511 32.3666 6.86707 27.3732 4.74695 25.253C-0.915651 19.5904 -0.915651 10.4096 4.74695 4.74695C10.4096 -0.915651 19.5904 -0.915651 25.253 4.74695C30.9157 10.4096 30.9157 19.5904 25.253 25.253Z" fill="#498DE2" stroke="#2377DD"/>
                  <text x="15" y="15" alignment-baseline="middle" text-anchor="middle"  font-size="16" font-family="Arial" fill="white">${letter}</text>
              </svg>`;
    },
    takeFullSnapshot() {

      this.map.capture((canvas) => {
        this.map_image_data = canvas.toDataURL();
      });
    }
  },
  async mounted() {
    await this.initMap();
  },
  computed: {
    alphabet() {
      return ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "AZ", "AA", "AB", "AC", "AD", "AE", "AF", "AG", "AH", "AI", "AJ", "AK", "AL", "AM", "AN", "AO", "AP", "AQ", "AR", "AS", "AT", "AU", "AV", "AW", "AX", "AY", "AZ"];
      // return "abcdefghijklmnopqrstuvwxyz".toUpperCase().split("")
    }
  },
  watch: {
    "reset_all" : {
      async handler(val) {
        if (val) {
          await this.map.removeObjects(this.map.getObjects());
          this.generated_route = false;
        }
      },
      deep: true
    },
    "is_edit" : {
      handler(val) {
        if (val) {
          this.map.getObjects().forEach(obj => {
            if (obj instanceof H.map.Marker) {
              obj.draggable = true;
            }
          });
          this.generated_route = false;
          return this.setupDragging();
        }
      },
      deep: true
    },
    "start_printing" : {
      async handler(val) {
        if (val) {
          await this.takeFullSnapshot();
        }
      },
      deep: true
    },
    "routeData" : {
      async handler() {
        await this.displayRoute();
      },
      deep: true
    },
    "selected_points" : {
      deep: true,
      async handler(val) {
        if (!val.length) return;
        let changed_point_index = val.length - 1;
        if(this.cloned_points) {
          let stop = false;
          this.cloned_points.forEach((point, index) => {
            if (!stop) {
              if (point.lat !== val[index].lat || point.lng !== val[index].lng) {
                changed_point_index = index;
                stop = true;
              }
            }
          });
        }
        await this.renderAddedPoints(val, changed_point_index);
        this.cloned_points = await structuredClone(val);
      },
    },
  }
};
</script>
<style lang='scss'>
.zoom-wrap {
  top: 120px;
  right: 50px;
  z-index: 100;
}

</style>